@import './$';
@import './helpers';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
  height: inherit;
  background-color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.24;
  font-family: 'Poppins', sans-serif;

  overflow: auto;
  overflow-x: hidden;
}

#root {
  height: inherit;

  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    height: inherit;
  }

  #terms-of-use main,
  #privacy-policy main,
  #accessibility-statement main {
    padding: 70px 0 0;
  }

  main {
    flex: 1 0 auto;
    position: relative;
    padding-top: 87px;

    transition: all 0.3s ease;

    @media screen and (max-width: $tablet-sm) {
      padding-top: 100.75px;
      overflow: hidden;
    }
  }

  img {
    display: block;
    max-width: 100%;
  }

  a {
    text-decoration: none;
  }

  textarea {
    resize: none;
  }
}

.red-button {
  padding: 12px 20px;
  color: #fff;
  // background-color: map-get($colors, 'red');
  background-color: #770b0b;
  font-weight: bold;
  text-transform: uppercase;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 0 10px 1px map-get($colors, 'red');
  }

  @media screen and (max-width: $tablet-sm) {
    padding: 8px;
    font-size: 14px;
  }
}

.section-title {
  font-weight: 600;
  font-size: 42px;
  line-height: 120%;
  color: map-get($colors, 'red');

  @media screen and (max-width: $tablet-sm) {
    font-size: 28px;
  }
}

.slick-slider {
  .slick-next,
  .slick-prev {
    width: 50px;
    height: 50px;
    background: #fff;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: -81px;
    cursor: pointer;
    border: 2px solid map-get($colors, 'red');

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      top: 14px;
      left: 16px;
      background-repeat: no-repeat;
      background-size: contain;
      width: 20px;
      height: 20px;

      @media screen and (max-width: $tablet-sm) {
        top: 15px;
        left: 18px;
        width: 15px;
        height: 15px;
      }
    }

    @media screen and (max-width: $tablet-sm) {
      top: -30px;
    }
  }
  .slick-next {
    right: 0;

    &::before {
      background-image: url('../static/arrow_right.svg');
      left: 18px;
    }

    @media screen and (max-width: $tablet-sm) {
      right: 10px;
    }
  }

  .slick-prev {
    right: 80px;
    left: unset;

    &::before {
      background-image: url('../static/arrow_left.svg');
    }

    @media screen and (max-width: $tablet-sm) {
      right: auto;
      left: 10px;
    }
  }
}

.mobile-image,
.mobile-btn {
  display: none;

  @media screen and (max-width: $tablet-sm) {
    display: block;
  }
}

.ticker {
  background-color: map-get($colors, 'red');
  color: #fff;
  height: 100px;

  &__text {
    animation: animMarquee 10s linear infinite;
    line-height: 100px;

    display: flex;
    align-items: center;

    p {
      font-weight: 300;
      font-size: 24px;
      white-space: nowrap;

      span {
        font-weight: 800;
        padding-right: 5px;
      }
    }

    .circle {
      background-color: #fff;
      border: 1px solid #fff;
      padding: 5px;
      border-radius: 50%;
      margin: 0 50px;
    }
  }
}

@keyframes animMarquee {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
