@import '../../styles/helpers/index.scss';

.about {
  .container__inner {
    @include space-between;
    padding-right: 0;

    border-top: 1px solid map-get($colors, 'red');

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }

    & > div {
      width: 100%;
      max-width: 50%;

      @media screen and (max-width: $tablet-sm) {
        max-width: 100%;
      }
    }
  }

  &__image {
    min-height: 650px;

    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;

    @media screen and (max-width: $tablet-sm) {
      display: none;
    }
  }

  &__content {
    padding: 95px 80px 0 40px;

    @media screen and (max-width: $tablet-sm) {
      padding: 30px 15px;
    }

    .section-title {
      margin: 0 0 40px;

      @media screen and (max-width: $tablet-sm) {
        margin: 0 0 30px;
      }
    }

    p {
      line-height: 1.5;
      &:not(:last-of-type) {
        margin: 0 0 15px;
      }
    }
  }

  &__text {
    white-space: pre-wrap;
  }
}
