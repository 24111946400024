@import '../../styles/helpers/index.scss';

.brands {
  .container {
    &__inner {
      padding: 55px 0 55px 40px;
      border-top: 1px solid map-get($colors, 'red');
      border-bottom: 1px solid map-get($colors, 'red');

      @media screen and (max-width: $tablet-sm) {
        padding: 55px 30px;
        text-align: center;
      }
    }
  }

  &__list {
    border-left: 1px solid map-get($colors, 'red');
    padding: 45px 0;

    @media screen and (max-width: $tablet-sm) {
      border-right: 1px solid map-get($colors, 'red');
    }

    div {
      @include align-center;
      gap: 20px;

      img {
        height: 80px !important;
        width: auto !important;
        margin: 0 auto;
        object-fit: contain;

        @media screen and (max-width: $tablet-sm) {
          width: 60%;
        }
      }
    }
  }

  &__array {
    border-left: 1px solid map-get($colors, 'red');
    padding: 0 0 55px 40px;
    columns: 3;

    @media screen and (max-width: $tablet-sm) {
      border-right: 1px solid map-get($colors, 'red');
      columns: 2;
      padding: 0 15px 55px;
    }

    li {
      font-size: 18px;
      line-height: 200%;
      color: map-get($colors, 'text');
      position: relative;
      text-indent: 25px;

      @media screen and (max-width: $tablet-sm) {
        font-size: 12px;
      }

      &::before {
        content: '';
        background-image: url('../../static/arrow_list.svg');
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 8px;
        left: 0;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 12px;
      }
    }
  }
}
